

export class ToolModel {
    id?: number
    name?: string
    path?: string
    tools?: Array<ToolModel>
    
    static fromJson(json: ToolModel): ToolModel {
        const data = Object.assign(new ToolModel(), json)
        if(json.tools) {
            data.tools = Object.assign(new Array<ToolModel>(), json.tools)
        }
        return data
    }
}