import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoutePath } from './common/route/route-path';
import RootPage from './common/widgets/root-page';
import { Suspense, lazy } from 'react';

const HomePage = lazy(() => import('./router/home'))
const UrlToolPage = lazy(() => import('./router/url-tool'))
const HtmlToolPage = lazy(() => import('./router/html-tool'))
const TextToolPage = lazy(() => import('./router/text-tool'))
const PasswordGeneratePage = lazy(() => import('./router/password-generate'))

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RootPage />}>
          <Route index path={RoutePath.initial} element={<Suspense><HomePage /></Suspense>} />
          <Route path={RoutePath.urlTool} element={<Suspense><UrlToolPage /></Suspense>} />
          <Route path={RoutePath.baseTool} element={<Suspense><HtmlToolPage /></Suspense>} />
          <Route path={RoutePath.classic} element={<Suspense><TextToolPage /></Suspense>} />
          <Route path={RoutePath.passwordGenerate} element={<Suspense><PasswordGeneratePage /></Suspense>} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

export default App;
